import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";

export async function getDeals(
  searchValue = "",
  offset = 1,
  perPage = 10,
  sortBy = "id",
  sortDir = "desc"
) {
  return await axiosInstance
    .get(
      `/tracker/deals?maxrows=${perPage}&startrow=${offset}&orderby=${sortBy}&orderdir=${sortDir}&query=${searchValue}`
    )
    .catch(handleErrors);
}

export const getDeal = async (id) => {
  return await axiosInstance.get(`/tracker/deal/${id}`).catch(handleErrors);
};

export const createDeal = async (deal) => {
  try {
    return await axiosInstance.post(`/tracker/deal`, deal);
  } catch (error) {
    throw error?.response?.data ?? error;
  }
};

export const updateDeal = async (id, deal) => {
  try {
    return await axiosInstance.put(`/tracker/deal/${id}`, deal);
  } catch (error) {
    throw error?.response?.data ?? error;
  }
};

export const deleteDeal = async (id) => {
  return await axiosInstance.delete(`/tracker/deal/${id}`).catch(handleErrors);
};
