import { useState, useEffect } from "react";
import { itemsPerPage as itemsPerPageDefault } from "../../../constants";
import {
  createDeal,
  deleteDeal,
  getDeals,
  showErrorToast,
  showSuccessToast,
  updateDeal,
} from "../../../helpers";

const useAdminDeals = ({
  currentPage = 0,
  doInitialFetch = true,
  itemsPerPage = itemsPerPageDefault,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    setLoading(true);
    setError(false);
    try {
      const { data } = await getDeals();
      const { deals, total } = data;
      setData(deals);
      setPageCount(Math.ceil(total / itemsPerPage));
    } catch (err) {
      showErrorToast();
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleEditDeal = async (dealToEditId, dealData) => {
    try {
      setLoading(true);
      await updateDeal(dealToEditId, dealData);
      showSuccessToast();
    } catch (error) {
      showErrorToast(error?.errorMessage ?? null);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleCreateDeal = async (dealData) => {
    try {
      setLoading(true);
      await createDeal(dealData);
      showSuccessToast();
    } catch (error) {
      showErrorToast(error?.errorMessage ?? null);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDeal = async (dealToDeleteId) => {
    try {
      setLoading(true);
      await deleteDeal(dealToDeleteId);
      showSuccessToast();
      await fetchData();
    } catch (error) {
      showErrorToast();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (doInitialFetch) {
      fetchData();
    }
  }, [currentPage]);

  return {
    data,
    loading,
    error,
    pageCount,
    searchTerm,
    setSearchTerm,
    fetchData,
    handleCreateDeal,
    handleEditDeal,
    handleDeleteDeal,
  };
};

export default useAdminDeals;
